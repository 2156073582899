export const careerUrl = "https://karmalife-talent.freshteam.com/jobs";
export const blogUrl = "https://karmalife.ai/blog/?utm_campaign=KarmaLifeWebsite&utm_medium=websiteheader";
export const blogAdvanceUrl = "https://karmalife.ai/blog/advance-cash/"
export const blogCreditUrl = "https://karmalife.ai/blog/credit-solutions-gig-workers/"
export const earlySalaryUrl = "https://karmalife.ai/blog/early-salary-employees/"
export const earnedWageUrl = "https://karmalife.ai/blog/earned-wage-access/"
export const financialUrl = "https://karmalife.ai/blog/financial-security-workers/"
export const onDemandUrl = "https://karmalife.ai/blog/ondemand-salary-workers/"
export const safeCreditUrl ="https://karmalife.ai/blog/safe-credit-workforce/"
export const inadvanceSalaryUrl = "https://karmalife.ai/blog/salary-inadvance/"
export const downloadApp = 'https://play.google.com/store/apps/details?id=in.onionlife.karmalife';
export const fbLink = "https://business.facebook.com/KarmaLifeAI";
export const linkedinLink = "https://www.linkedin.com/company/karmalifeai";
export const twitterLink = "https://twitter.com/Karmalife_AI ";
export const contactUsLink = 'mailto:contact@karmalife.ai';
export const pageLink = {
    home: '/',
    about: '/about',
    ourSolutions: '/our-solutions',
    media: '/media',
    faq: '/faq',
    privacyPolicy: '/privacy-policy',
    termsCondition: '/terms-and-conditions'

}